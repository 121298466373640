/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.user-list-card {
  margin-bottom: 32px;
}
.user-list-card::v-deep > .ant-card-body {
  background: #f8f8f8;
  border-top: 1px solid #d9d9d9;
}
